.daily-business-summary {

  &__live {
    border-radius: 16;
    overflow: none;
    padding: 16;
    background-color: 'green';
  }

  &__layout_size {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }

  &__row-gap-24 {
    row-gap: 24px;
  }

  &__dropdown {
    width: 160px;
    margin-left: 24px;
  }

  &__card-wrapper {
    padding: 20px 0;
    background-color: white;
    position: sticky;
    top: 5rem;
    z-index: 5;
  }

  &__table-header {
    position: sticky;
    background-color: white;
  }

  &__date-picker {
    width: 180px !important;
    cursor: pointer;
    display: flex;
    // z-index: 1000;
  }

  &__table-container {
    width: 90vw !important;
  }

  .react-datepicker-wrapper {
    width: 120px;
  }

  &__date-picker-arrows {
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
  }
}