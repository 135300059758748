@import "../../styles/abstract/colors";

.dashboard-overview-modal {
  &__hr {
    border: 0;
    background: #c5c5c5;
    border-bottom: 2px solid #c5c5c5;
    margin: 30px 0;
  }
  &__row-gap-24 {
    row-gap: 24px;
  }
  &__header {
    background: rgb(2, 0, 36);
    background: #0a4091;
    color: $white;
    padding: 16px;
  }

  &__content {
    padding: 16px 8px;
    max-height: 60vh;
    overflow-y: auto;
  }

  &__sub-header {
    font-size: 14px;
  }

  &__input {
    height: 80px;
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}
