.admin-forecast-header {
  padding: 16px 64px;
  display: flex;
  justify-content: start

  &__date-picker {
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    width: 120px;
  }

  &__date-picker-arrows {
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
  }

  &__history-block,
  &__toggle-block {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 4px 8px;
    cursor: pointer;
  }

  &__dropdown {
    width: 160px;
    margin-left: 24px;
  }

  // &__toggle-block {
  //   margin-right: 8px;
  // }
}
