.sidebar-menu {
  &__wrapper {
    padding: 10px 16px 10px 11px;
    background-color: #3f4159;
    color: whitesmoke;
    margin-top: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid #3f4159;
    transition: border-left 200ms;
    &:hover {
      border-left: 4px solid whitesmoke;
    }
  }
  &__menu_item {
    border-top: 1px solid rgba(245, 245, 245, 0.24);
    background-color: #3f4159;
    color: whitesmoke;
    padding: 10px 16px 10px 40px;
    border-left: 4px solid #3f4159;
    cursor: pointer;
    transition: border-left 200ms;
    &:hover {
      border-left: 4px solid whitesmoke;
    }
  }
  &__icon {
    margin-bottom: -5px;
  }
  &__chevron {
    margin-bottom: -5px;
    transition: transform 300ms, margin-bottom 300ms;
  }
  &__chevron_open {
    transform: rotate(90deg);
    transition: transform 300ms, margin-bottom 300ms;
    margin-bottom: -8px;
  }
}
