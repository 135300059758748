.graph-table-view {
    &__grand-total {
      background-color: #e0f0e5 !important;
    }
  
    &__BreakFast {
      background-color: #41464b;
  
      &--child {
        background-color: #41464bc9;
      }
  
      &--collapsed {
        background-color: #41464bad;
      }
    }
  
    &__Lunch {
      background-color: #eb9b23;
  
      &--child {
        background-color: #dfae64;
      }
  
      &--collapsed {
        background-color: #dfbe8d;
      }
    }
  
    &__Snack {
      background-color: #08a008;
  
      &--child {
        background-color: #34b434;
      }
  
      &--collapsed {
        background-color: #68c568;
      }
    }
  
    &__Dinner {
      background-color: #2125ec;
  
      &--child {
        background-color: #383bd4;
      }
  
      &--collapsed {
        background-color: #6768d1;
      }
    }
  
    &__Mid-night {
      background-color: #b60c0c;
  
      &--child {
        background-color: #a73939;
      }
  
      &--collapsed {
        background-color: #ac5a5a;
      }
    }
  }
  