.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #0f1230;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  main {
    flex-grow: 1;
  }

  &__item {
    padding: 10px 16px 10px 11px;
    background-color: #3f4159;
    color: whitesmoke;
    margin-top: 4px;
    cursor: pointer;
    border-left: 4px solid #3f4159;
    transition: border-left 200ms;
    &:hover {
      border-left: 4px solid whitesmoke;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer-button {
    color: whitesmoke;
    background-color: #120217;
    height: 64px;
    width: 100%;
    border: none;
    font-weight: bold;
  }
}
