.admin-delete-item-modal {
  &__close_icon {
    opacity: 0.5;
    transition: opacity 100ms;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
