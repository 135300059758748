@import "../../styles//abstract/colors";

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121212;

  &__login-box {
    min-width: 400px;
    margin-top: -80px;
    // border: 1px solid $blue;
    background: #1f1f1f;
    color: whitesmoke;
  }

  &__logo {
    height: 54px;
  }

  &__btn {
    width: 120px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    font-size: 12px;
    &__link {
      cursor: pointer;
    }
  }
}
