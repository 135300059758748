$blue: #2596be;
$white: #ffffff;
$lightgray: #ededed;
$lightblue: #eaf0f9;
$black: #000000;

// TILES COLOR CODES
$tilegreen: #cae7ba;
$tileblue: #feb15b;
$tileorange: #42df69;
$tilelightred: #582a2100;
$tilered: #200503;

// BUTTONS GREEN
$btngreen: #cae7b5;
$btnred: #e41812;
