.date-picker-wrapper {
  &__date-picker-wrapper {
    cursor: pointer;
    display: flex;
    width: 180px;
  }

  &__date-picker-arrows {
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    cursor: pointer;
  }
}
