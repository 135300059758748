.reset-password-modal {
  position: relative;
  &__close_icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0.5;
    transition: opacity 100ms;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
  }
  &__warning_text {
    background-color: #ffd5d5;
    color: #c42b2b;
    border-radius: 34px;
  }
}
